import React from 'react';
import DiscussProject from 'components/parts/discuss-project';
import FeaturedWork from 'components/parts/featured-work';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

import 'components/style.scss';

const LayoutProjectLex = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      screenshot1: file(relativePath: { eq: "projectlex/screenshot1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screenshot2: file(relativePath: { eq: "projectlex/screenshot2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      screenshot3: file(relativePath: { eq: "projectlex/screenshot3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <div className="big62-section">
      <div className="big62-container">
        <h2 className="title is-7 is-family-secondary">FEATURED WORK</h2>
        <div className="margin-top-3 margin-bottom-5">
          <FeaturedWork />
        </div>
        <div className="margin-top-3 margin-bottom-3">
          <h1 className="title is-main-title is-family-secondary">
            <span className="has-text-big62pink">Redefining</span> 3d action{' '}
            <span className="has-text-big62pink">gaming</span> on iOS and Android.
          </h1>
        </div>
        <div className="box embed-container margin-bottom-3">
          <iframe src="https://youtube.com/embed/O7CBywWwhm4" frameborder="0" allowfullscreen></iframe>
        </div>
        <div className="content margin-top-1 margin-bottom-3">
          <h2 className="title is-7 is-family-secondary">OVERVIEW</h2>
          <p className="is-body-small-text fix-spacing">
            <br />A fast paced 3d game application developed for iPhone, iPad and Android platforms. The full breadth of
            development spanned <span className="has-text-big62pink">game design</span>,{' '}
            <span className="has-text-big62pink">art</span>,{' '}
            <span className="has-text-big62pink">3d engine development</span>,{' '}
            <span className="has-text-big62pink">sound design</span>,{' '}
            <span className="has-text-big62pink">monetization</span> and{' '}
            <span className="has-text-big62pink">innovative interface design.</span>
          </p>
          <br />
          <p className="is-body-small-text fix-spacing">
            Project Lex is a perfect case study of game development project from a simple idea to full production.
          </p>
          <br />
          <p className="is-body-small-text fix-spacing">
            The following is a further detailed break down of the design and technology aspects of the project.
          </p>
          <br />
          <br />
          <h2 className="title is-7 is-family-secondary">DESIGN</h2>
          <ul className="is-body-small-text fix-spacing" indent>
            <li>Game design</li>
            <li>Modelling 3d high resolution character, environment and objects</li>
            <li>Texture mapping</li>
            <li>Hundreds of character animations</li>
            <li>Particle FX</li>
            <li>User interface</li>
            <li>Conceptual art</li>
            <li>Original music score</li>
            <li>Sound design</li>
            <li>Branding</li>
            <li>Marketing Material</li>
          </ul>
          <p className="is-body-small-text fix-spacing">
            <br />
          </p>
          <h2 className="title is-7 is-family-secondary">TECHNOLOGY</h2>
          <ul className="is-body-small-text fix-spacing">
            <li>Custom cross-platform C++ 3D game engine</li>
            <li>Support for mobile, tablet and PC.</li>
            <li>DirectX</li>
            <li>OpenGL ES</li>
            <li>Dynamic lighting</li>
            <li>HLSL/GLSL based material and shader system</li>
            <li>Batching support to reduce draw calls</li>
            <li>Dynamic input support including touch, mouse + keyboard and game controllers</li>
            <li>Scriptable UI system</li>
            <li>FMOD audio implementation</li>
            <li>Ad and store integrations for monetisation</li>
          </ul>
        </div>
        <br />
        <br />
        <Img className="bordered-image" fluid={data.screenshot1.childImageSharp.fluid} alt="Screenshot 1" />
        <br />
        <br />
        <Img className="bordered-image" fluid={data.screenshot2.childImageSharp.fluid} alt="Screenshot 2" />
        <br />
        <br />
        <Img className="bordered-image" fluid={data.screenshot3.childImageSharp.fluid} alt="Screenshot 3" />
        <br />
        <div className="big62-section" className="margin-top-4">
          <DiscussProject nextUrl="/work/humantrak" />
        </div>
      </div>
    </div>
  );
};

export default LayoutProjectLex;
